import { HeadingLevel } from '@/components/common/MarkUp';
import { Title } from '@/components/common/Title';
import { desktopTitleStartPosition } from '@/components/common/utils';
import { Col, Row } from '@/components/layout/Grid';
import { Section } from '@/components/layout/Section';

import type { SanityStandaloneHeadingType } from '@/types/sanity';

export interface StandaloneTitleProps extends SanityStandaloneHeadingType {
  size?: 'xl' | 'lg' | 'md' | 'sm';
}

export const StandaloneTitle = ({
  tag,
  heading,
  headingLevel,
  desktopAlignment,
  mobileAlignment,
  caption,
  subheading,
  paddingAdjustment = 'default',
  blockSpacing,
  backgroundColour,
  size,
  widthConstraint = 'full',
}: StandaloneTitleProps) => {
  const sizeMap = {
    1: 'xl',
    2: 'lg',
    3: 'md',
    4: 'sm',
  };

  const transformedSize = size || sizeMap[headingLevel];

  return (
    <Section
      backgroundColour={backgroundColour?.token}
      verticalPadding={paddingAdjustment}
      spacing={blockSpacing}
      className="standalone-heading"
    >
      <Row>
        <Col
          sizes={{
            sm: widthConstraint === 'narrow' ? 8 : 12,
          }}
          start={{
            sm: desktopTitleStartPosition(widthConstraint, desktopAlignment),
          }}
        >
          <HeadingLevel>
            <Title
              badge={tag?.tagText}
              caption={caption}
              heading={heading}
              subheading={subheading}
              desktopAlignment={desktopAlignment}
              mobileAlignment={mobileAlignment}
              hidden={false}
              variant="hero"
              size={transformedSize}
              badgeColour={tag?.tagColour}
              isNarrow={widthConstraint === 'narrow'}
            />
          </HeadingLevel>
        </Col>
      </Row>
    </Section>
  );
};
